import React, { useEffect, useState } from 'react';
// import Button from '@material-ui/core/Button'
// import Container from '@material-ui/core/Container'
// import Link from '@material-ui/core/Link'
// import Typography from '@material-ui/core/Typography'
import Carousel from 'nuka-carousel';
import { Modal } from '@material-ui/core';
import { Link, scroller, Element } from 'react-scroll';
import HomeInfo from '../../domain/models/home-info';
import Image from 'next/image';

type PropsType = {
  homeInfo: HomeInfo;
};

export default function IndexPresenter(props: PropsType) {
  const { homeInfo } = props;
  const [groupedTeamMembers, setGroupedTeamMembers] = useState([]);
  const [openTeamMemberModal, setOpenTeamMemberModal] = useState(null);
  const isModalOpen = openTeamMemberModal != null;

  useEffect(() => {
    const newRows = [];
    if (homeInfo?.team_members) {
      let currentRow = [];
      let counter = 0;
      for (let i = 0; i < homeInfo.team_members.length; i++) {
        if (counter > 3) {
          newRows.push(currentRow);
          currentRow = [];
          counter = 0;
        }
        const t = homeInfo.team_members[i];
        currentRow.push(t);
        counter += 1;
      }
      if (currentRow.length > 0) {
        newRows.push(currentRow);
      }
    }
    setGroupedTeamMembers(newRows);
  }, [homeInfo]);

  const renderIntroLink = () => {
    if (homeInfo?.intro?.link) {
      if (homeInfo.intro.link.startsWith('#')) {
        return (
          <Link
            to={homeInfo.intro.link.replace('#', '')}
            spy={true}
            hashSpy={true}
            smooth={true}
            duration={500}
            offset={-100}
            href={homeInfo.intro.link}
            className="btn btn-orange pull-right why-btn">
            {homeInfo.intro.link_title}
          </Link>
        );
      }
      return (
        <a href={homeInfo.intro.link} className="btn btn-orange pull-right why-btn">
          {homeInfo.intro.link_title}
        </a>
      );
    }
    return <span />;
  };

  const renderIntro = () => {
    return (
      <section id="intro">
        <Carousel
          autoplay={true}
          autoplayInterval={5000}
          withoutControls={true}
          pauseOnHover={false}
          swiping={false}
          dragging={false}
          wrapAround={true}>
          {homeInfo?.intro?.slides &&
            homeInfo.intro.slides
              .filter((o) => o.media && o.media.url)
              .map((o, i) => (
                <img key={i.toString()} src={o.media.url} data-testid="intro-slide" />
              ))}
        </Carousel>
        <div className="caption">
          <h1>{homeInfo.intro.title}</h1>
          <p>{homeInfo.intro.description}</p>
          {renderIntroLink()}
        </div>
      </section>
    );
  };

  const renderAbout = () => {
    return (
      <section className="about">
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%'
          }}>
          <Carousel
            autoplay={true}
            withoutControls={true}
            pauseOnHover={false}
            swiping={false}
            dragging={false}
            wrapAround={true}
            transitionMode="fade">
            {homeInfo?.about?.slides &&
              homeInfo.about.slides.map((o, i) => (
                <img key={i.toString()} src={o.media.url} data-testid="about-slide" />
              ))}
          </Carousel>
        </div>
        <div className="container pad-top-20">
          <h3 className="header">
            <span className="orange">+</span>
            {homeInfo.about.title}
          </h3>
          <div className="row pad-top-20">
            <div className="col-md-5">
              <h2 className="orange large">{homeInfo.about.title}</h2>
              <hr className="orange" />
              <h4>{homeInfo.about.subtitle}</h4>
              <p>{homeInfo.about.description}</p>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderTeam = () => {
    return (
      <section id="team" className="team">
        <div className="container">
          <h3 className="header white">
            <span className="grey">+</span>Team
          </h3>
          <h2>Get to Know Us</h2>
          <hr className="white" />

          {groupedTeamMembers.map((row, i) => (
            <div key={i} className="row row-centered" style={i > 0 ? { marginTop: 20 } : {}}>
              {row.map((t) => (
                <div key={t.id} className="col-md-3 col-centered">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenTeamMemberModal(t);
                    }}>
                    {/* <img src={t.photo.url} data-testid="team-photo" /> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Image
                        src={t.photo.thumbnailLargeUrl}
                        alt={t.name}
                        width={210}
                        height={315}
                        objectFit="cover"
                        data-testid="team-photo"
                      />
                    </div>
                    <h4>{t.name}</h4>
                    <h5>{t.byline}</h5>
                  </a>
                </div>
              ))}
            </div>
          ))}

          <hr className="white" />
        </div>
      </section>
    );
  };

  const handleOpenTeamMemberModal = (teamMember) => {
    setOpenTeamMemberModal(teamMember);
  };

  const handleCloseTeamMemberModal = () => {
    setOpenTeamMemberModal(null);
  };

  const renderModal = () => {
    return (
      <>
        <Modal
          open={isModalOpen}
          onClose={() => {
            handleCloseTeamMemberModal();
          }}>
          {openTeamMemberModal ? (
            <div>
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-body">
                    <div
                      className="container"
                      onClick={() => {
                        handleCloseTeamMemberModal();
                      }}>
                      <div className="row">
                        {openTeamMemberModal.photo && (
                          <div className="col-md-3">
                            <img src={openTeamMemberModal.photo.url} className="img-center" />
                          </div>
                        )}
                        <div className="col-md-6">
                          <h2 className="large">
                            {openTeamMemberModal.name.toUpperCase()}
                            <br />
                            <span className="title">
                              {openTeamMemberModal.byline.toUpperCase()}
                            </span>
                          </h2>
                          <hr className="orange" />
                          {openTeamMemberModal.bio.indexOf('\n') >= 0 ? (
                            openTeamMemberModal.bio
                              .split('\n')
                              .map((line, i) => <p key={i}>{line}</p>)
                          ) : (
                            <p>{openTeamMemberModal.bio}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        handleCloseTeamMemberModal();
                      }}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </Modal>
      </>
    );
  };

  const renderVoxSocial = () => {
    return (
      <section id="vox-social" className="vox-social white">
        <div className="container pad-top-20">
          <h3 className="header">
            <span className="orange">+</span>
            {homeInfo.vox_social.title}
          </h3>
          <div className="row pad-top-20">
            <div className="col-md-5">
              <h2 className="orange large">{homeInfo.vox_social.title}</h2>
              <hr className="orange" />
              <p className="white">{homeInfo.vox_social.description}</p>
            </div>
            <div className="col-md-offset-2 col-md-4">
              <Carousel
                autoplay={true}
                withoutControls={true}
                pauseOnHover={false}
                swiping={false}
                dragging={false}
                wrapAround={true}
                transitionMode="fade">
                {homeInfo?.vox_social?.slides &&
                  homeInfo.vox_social.slides.map((o, i) => (
                    <img key={i.toString()} src={o.media.url} data-testid="vox-social-slide" />
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      {homeInfo?.intro && renderIntro()}
      {homeInfo?.about && renderAbout()}
      {homeInfo?.team_members && renderTeam()}
      {homeInfo?.vox_social && renderVoxSocial()}
      {renderModal()}
    </>
  );
}

import Media from "./media";

export class HomeInfoSlide {
  id: number;
  media: Media;
  fromJson(data): HomeInfoSlide {
    this.id = data.id;
    if (data.media) {
      this.media = new Media().fromJson(data.media);
    }
    return this;
  }
}

export class HomeInfoIntro {
  id: number;
  title: string;
  description: string;
  link: string;
  link_title: string;
  slides: HomeInfoSlide[];
  fromJson(data): HomeInfoIntro {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.link = data.link;
    this.link_title = data.link_title;
    if (data.slides) {
      this.slides = data.slides.map((o) => new HomeInfoSlide().fromJson(o));
    }
    return this;
  }
}

export class HomeInfoAbout {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  slides: HomeInfoSlide[];
  fromJson(data): HomeInfoAbout {
    this.id = data.id;
    this.title = data.title;
    this.subtitle = data.subtitle;
    this.description = data.description;
    if (data.slides) {
      this.slides = data.slides.map((o) => new HomeInfoSlide().fromJson(o));
    }
    return this;
  }
}

export class HomeInfoTeamMember {
  id: number;
  name: string;
  byline: string;
  bio: string;
  photo: Media;
  fromJson(data): HomeInfoTeamMember {
    this.id = data.id;
    this.name = data.name;
    this.byline = data.byline;
    this.bio = data.bio;
    if (data.photo) {
      this.photo = new Media().fromJson(data.photo);
    }
    return this;
  }
}

export class HomeInfoVoxSocial {
  id: number;
  title: string;
  description: string;
  slides: HomeInfoSlide[];
  fromJson(data): HomeInfoVoxSocial {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    if (data.slides) {
      this.slides = data.slides.map((o) => new HomeInfoSlide().fromJson(o));
    }
    return this;
  }
}

class HomeInfo {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  intro: HomeInfoIntro;
  about: HomeInfoAbout;
  team_members: HomeInfoTeamMember[];
  vox_social: HomeInfoVoxSocial;

  fromJson(data): HomeInfo {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.intro) {
      this.intro = new HomeInfoIntro().fromJson(data.intro);
    }
    if (data.about) {
      this.about = new HomeInfoAbout().fromJson(data.about);
    }
    if (data.team_members) {
      this.team_members = data.team_members.map((o) =>
        new HomeInfoTeamMember().fromJson(o)
      );
    }
    if (data.vox_social) {
      this.vox_social = new HomeInfoVoxSocial().fromJson(data.vox_social);
    }
    return this;
  }
}

export default HomeInfo;

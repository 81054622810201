import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import DefaultLayout from '../presentation/layouts/default';
import IndexPresenter from '../presentation/presenters/index-presenter';
import { GetPageDefaultsInteractor } from '../domain/usecases/get-page-defaults-interactor';
import { SiteInfoService } from '../data/services/data-site-info-service';
import { GetHomeInteractor } from '../domain/usecases/get-home-interactor';
import { HomeInfoService } from '../data/services/data-home-info-service';

export default function IndexPage() {
  const [siteInfo, setSiteInfo] = useState(null);
  const [homeInfo, setHomeInfo] = useState(null);

  useEffect(() => {
    fetchPageDefaults();
    fetchHome();
  }, []);

  const fetchPageDefaults = async () => {
    const getPageDefaultsInteractor = new GetPageDefaultsInteractor(new SiteInfoService());
    const siteInfo = await getPageDefaultsInteractor.getSiteInfo();
    setSiteInfo(siteInfo);
  };

  const fetchHome = async () => {
    const getHomeInteractor = new GetHomeInteractor(new HomeInfoService());
    const homeInfo = await getHomeInteractor.getHomeInfo();
    setHomeInfo(homeInfo);
  };

  return (
    <DefaultLayout siteInfo={siteInfo}>
      <Head>
        <title>{siteInfo?.title}</title>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
          integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u"
          crossOrigin="anonymous"
        />
      </Head>
      <IndexPresenter homeInfo={homeInfo} />
    </DefaultLayout>
  );
}

import { HomeInfoServiceType } from "../services/home-info-service";
import HomeInfo from "../models/home-info";

export class GetHomeInteractor {
  homeInfoService: HomeInfoServiceType;

  constructor(homeInfoService: HomeInfoServiceType) {
    this.homeInfoService = homeInfoService;
  }

  async getHomeInfo(): Promise<HomeInfo> {
    return this.homeInfoService.getHomeInfo();
  }
}

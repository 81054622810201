import { HomeInfoServiceType } from "../../domain/services/home-info-service";
import fetch from "isomorphic-unfetch";
import HomeInfo from "../../domain/models/home-info";
import { parseJsonRes } from "../../utils/parse-res";

export class HomeInfoService implements HomeInfoServiceType {
  async getHomeInfo(): Promise<HomeInfo> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_WEB_API_URL}/home-info`);
    const resData = await parseJsonRes(res);
    const homeInfo = new HomeInfo().fromJson(resData);
    return homeInfo;
  }
}
